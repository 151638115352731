import React, { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

function ContentList({ title, content }) {
  const [clickedItem, setClickedItem] = useState(null);
  const [showEmbed, setShowEmbed] = useState(false);
  const musicRef = useRef();
  const commercialRef = useRef();
  const videoRef = useRef();
  const isMobile = useMediaQuery({ query: "(max-device-width: 800px)" });

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!musicRef.current?.contains(e.target) && 
          !commercialRef.current?.contains(e.target) &&
          !videoRef.current?.contains(e.target)) {
        setClickedItem(null);
        setShowEmbed(false);
      }
    };

    const handleKeyDown = (e) => {
      if (!clickedItem) return;
      
      const [category, index] = clickedItem.split('-');
      const maxIndex = content[category === 'music' ? 0 : 1].length - 1;
      
      if (e.key === 'ArrowDown') {
        if (category === 'music' && index === maxIndex) {
          setClickedItem('commercial-0');
        } else if (category === 'commercial' && index === maxIndex) {
          setClickedItem(null);
        } else {
          setClickedItem(`${category}-${parseInt(index) + 1}`);
        }
      } else if (e.key === 'ArrowUp') {
        if (category === 'music' && index === 0) {
          setClickedItem(null);
        } else if (category === 'commercial' && index === 0) {
          setClickedItem(`music-${content[0].length - 1}`);
        } else {
          setClickedItem(`${category}-${parseInt(index) - 1}`);
        }
      }
      setShowEmbed(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [clickedItem, content]);

  const handleItemClick = (type, index) => {
    setClickedItem(`${type}-${index}`);
    setShowEmbed(false);
  };

  const handleVideoClick = (e) => {
    e.stopPropagation();
    setShowEmbed(!showEmbed);
  };

  const renderList = (items, type, listRef) => (
    <div className="list" ref={listRef}>
      <ul className="artistColumn">
        {items.map((item, index) => (
          <li
            key={index}
            className={clickedItem === `${type}-${index}` ? "selected" : ""}
            onClick={() => handleItemClick(type, index)}
          >
            {item.artist}
          </li>
        ))}
      </ul>
      <ul className="songColumn">
        {items.map((item, index) => (
          <li
            key={index}
            className={clickedItem === `${type}-${index}` ? "selected" : ""}
            onClick={() => handleItemClick(type, index)}
          >
            {item.song}
          </li>
        ))}
      </ul>
    </div>
  );

  const renderVideo = () => {
    if (!clickedItem) return null;
    
    const [category, index] = clickedItem.split('-');
    const item = content[category === 'music' ? 0 : 1][index];
    
    return (
      <div className="highlightedVidWrapper" ref={videoRef}>
        <div className="videoLink" onClick={handleVideoClick}>
          {isMobile || showEmbed ? (
            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
              <iframe
                title={item.song}
                src={item.href}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
            </div>
          ) : (
            <video playsInline muted loop autoPlay key={item.src}>
              <source src={require(`../assets/${item.src}`)} type="video/mp4" />
            </video>
          )}
          <h3>
            <span>{item.artist}</span> <span>{item.song}</span>
          </h3>
        </div>
      </div>
    );
  };

  return (
    <section className="contentListWrapper">
      <h2>{title[0]}</h2>
      {renderList(content[0], 'music', musicRef)}
      {renderVideo()}
      <h2>{title[1]}</h2>
      {renderList(content[1], 'commercial', commercialRef)}
    </section>
  );
}

export default ContentList;